// src/Pages/1-Header/Navigation.js
import React, { useRef, useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import './Navigation.css';
import logo from '../Images/logo192.png';
import { FaBars, FaTimes } from "react-icons/fa";
import { useAuth } from '../login/AuthContext';
import { db } from '../Component/Firebase';
import { doc, getDoc } from 'firebase/firestore';

const Navigation = () => {
    const navRef = useRef();
    const { currentUser, logout } = useAuth();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userName, setUserName] = useState('');
    const [userProfilePic, setUserProfilePic] = useState('');

    const defaultProfilePic = require('../Images/img_login.webp');

    const fetchUserData = useCallback(async () => {
        if (currentUser) {
            const photoURL = currentUser.photoURL || defaultProfilePic;
            setUserProfilePic(photoURL);

            const userDocRef = doc(db, 'users', currentUser.uid);
            const userSnapshot = await getDoc(userDocRef);

            if (userSnapshot.exists()) {
                const userData = userSnapshot.data();
                setUserName(userData.firstName + ' ' + userData.lastName);
            } else {
                setUserName(currentUser.displayName || 'مستخدم جديد');
            }
        }
    }, [currentUser, defaultProfilePic]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const closeNavbar = () => {
        if (navRef.current.classList.contains("responsive-nav")) {
            navRef.current.classList.remove("responsive-nav");
        }
        setDropdownOpen(false);
    }

    const toggleNavbar = () => {
        navRef.current.classList.toggle("responsive-nav");
    }
    
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    return (
        <header className="navigation-header">
            <Link to="/" onClick={closeNavbar}>
                <img src={logo} alt='logo' className="logo" loading='lazy' />
            </Link>
            <nav ref={navRef} className="navigation-menu">
                <Link to="/" onClick={closeNavbar} className="nav-link">الصفحة الرئيسية</Link>
                <Link to="/Blog" onClick={closeNavbar} className="nav-link">المدونة</Link>
                <Link to="/Psychological" onClick={closeNavbar} className="nav-link">مقاييس نفسية</Link>
                <Link to="/DownloadPDF" onClick={closeNavbar} className="nav-link">تحميل PDF</Link>

                {currentUser ? (
                    <div className="user-container">
                        <img
                            src={userProfilePic}
                            alt={userName}
                            className="user-profile-pic"
                            onClick={toggleDropdown}
                        />
                        {dropdownOpen && (
                            <div className="dropdown-menu">
                                <span className="user-name">{userName}</span>
                                <Link to="/OrderStatus" onClick={closeNavbar} className="dropdown-link">متابعة الطلبات</Link>
                                <Link to="/PaymentPage" onClick={closeNavbar} className="dropdown-link">صفحة الطلبات</Link>
                                <button className="logout-button" onClick={logout}>تسجيل الخروج</button>
                            </div>
                        )}
                    </div>
                ) : (
                    <Link to="/LoginPage" onClick={closeNavbar} className="nav-link">تسجيل الدخول</Link>
                )}

                <button className="nav-button nav-close-button" onClick={toggleNavbar}>
                    <FaTimes />
                </button>
            </nav>
            <button className="nav-button" onClick={toggleNavbar}>
                <FaBars />
            </button>
        </header>
    );
}

export default Navigation;
