// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // تأكد من استيراد getFirestore

import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyBtSM3s7Altn1GYmPtMBOi-TICA3-aIx_U",
  authDomain: "userauth-614c1.firebaseapp.com",
  projectId: "userauth-614c1",
  storageBucket: "userauth-614c1.appspot.com",
  messagingSenderId: "282262633182",
  appId: "1:282262633182:web:35b5842f22c015007fa04f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Initialize Firestore and export the db reference
export const db = getFirestore(app); // تهيئة Firestore وتصدير db
export { auth, googleProvider };


export const storage = getStorage(app); // أضف هذا السطر
