import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../Component/Firebase'; 
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore'; 
import { addDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [viewCount, setViewCount] = useState(0);
    const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async user => {
            setCurrentUser(user);

            if (user) {
                const userDoc = await getDoc(doc(db, 'users', user.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setViewCount(userData.viewCount); 
                    setSubscriptionEndDate(userData.subscriptionEndDate);

                    // تحقق من انتهاء الاشتراك وإعادة تعيين viewCount إلى صفر إذا لزم الأمر
                    const today = new Date();
                    const endDate = new Date(userData.subscriptionEndDate);
                    if (today > endDate) {
                        setViewCount(0); // تعيين viewCount إلى صفر
                        await setDoc(doc(db, 'users', user.uid), { viewCount: 0 }, { merge: true });
                        alert("انتهت مدة الاشتراك، وتم إعادة الرصيد إلى صفر");
                    }
                }
            }
        });
        return () => unsubscribe();
    }, []);

    const logout = async () => {
        try {
            await auth.signOut();
            navigate('/LoginPage');
        } catch (error) {
        }
    };

    const register = async (firstName, lastName, email, password) => {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        try {
            const startDate = new Date();
            const endDate = addDays(startDate, 30);
            await setDoc(doc(db, 'users', user.uid), {
                uid: user.uid,
                firstName,
                lastName,
                email,
                viewCount: 0,
                isAdmin: false,
                subscriptionEndDate: endDate.toISOString()
            });
        } catch (error) {
        }

        return user;
    };

    const login = async (email, password) => {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
            const userData = userDoc.data();
            setViewCount(userData.viewCount);
            setSubscriptionEndDate(userData.subscriptionEndDate);

            // تحقق من انتهاء الاشتراك وإعادة تعيين viewCount إلى صفر إذا لزم الأمر
            const today = new Date();
            const endDate = new Date(userData.subscriptionEndDate);
            if (today > endDate) {
                setViewCount(0); // تعيين viewCount إلى صفر
                await setDoc(doc(db, 'users', user.uid), { viewCount: 0 }, { merge: true });
                alert("انتهت مدة الاشتراك، وتم إعادة الرصيد إلى صفر");
            }
        }

        return user;
    };

    const decrementViewCount = async () => {
        const today = new Date();
        const endDate = new Date(subscriptionEndDate);

        if (currentUser && viewCount > 0 && today <= endDate) {
            const newViewCount = viewCount - 1;
            setViewCount(newViewCount);
            try {
                await setDoc(doc(db, 'users', currentUser.uid), { viewCount: newViewCount }, { merge: true });
            } catch (error) {
            }
        } else if (today > endDate) {
            alert("انتهت مدة الاشتراك");
        } else {
            alert("لقد استنفذت عدد المرات المتاحة");
        }
    };

    return (
        <AuthContext.Provider value={{ currentUser, logout, register, login, viewCount, decrementViewCount }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
